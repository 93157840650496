import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import { FC } from 'react'

import Container from '../../../components/Container'
import { SubTitleStyled, TitleStyled } from '../../../components/common/Title'
import { useIntlLabel } from '../../../intl'
import ItemServiceUnpopular from './ItemServiceUnpopular'
import { HomeServiceUnpopularSectionQuery } from './__generated__/HomeServiceUnpopularSectionQuery'

const ContainerStyled = styled.section((props) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
}))

/*const TitleStyled = styled.h2({
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 4,
})

const SubTitleStyled = styled.p(({ theme }) => ({
    padding: 0,
    marginBottom: theme.spacing(4),
    color: theme.color.neutral.slate[300],
    fontWeight: 400,
    fontSize: theme.spacing(2),
}))*/

const ContainerListStyled = styled.div((props) => ({
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: props.theme.spacing(-3),
    marginRight: props.theme.spacing(-3),
    [props.theme.breakpoints.down(props.theme.breakpoints.sm)]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}))

const HomeServiceUnpopularSection: FC = () => {
    const getIntlLabel = useIntlLabel()
    const intl = useIntl()

    const { allStrapiServiceCategory, strapiHome, strapiGlobal } =
        useStaticQuery<HomeServiceUnpopularSectionQuery>(
            graphql`
                query HomeServiceUnpopularSectionQuery {
                    allStrapiServiceCategory(limit: 12, sort: { order: DESC, fields: created_at }) {
                        nodes {
                            categoryDescription_mg
                            categoryDescription
                            categorySlug
                            categorySlug_mg
                            categoryName_mg
                            categoryName
                            icon {
                                url
                            }
                            id
                            services {
                                id
                            }
                        }
                    }
                    strapiHome {
                        titleUnpopularServices
                        titleUnpopularServices_mg
                        titleVeryRequestedServices
                        titleVeryRequestedServices_mg
                        titlePopularServices
                        titlePopularServices_mg
                    }
                    strapiGlobal {
                        serviceVeryRequested {
                            labelService
                            labelService_mg
                        }
                    }
                }
            `,
        )

    return (
        <ContainerStyled>
            <Container>
                <TitleStyled>{getIntlLabel(strapiHome, 'titlePopularServices')}</TitleStyled>
                <SubTitleStyled>
                    {intl.formatMessage({ id: 'home_popular_service_subtitle' })}
                </SubTitleStyled>
                <ContainerListStyled>
                    {allStrapiServiceCategory.nodes
                        ?.filter((item) => item?.services)
                        .map((item, index) => (
                            <ItemServiceUnpopular
                                icon={item.icon?.url!!}
                                key={`ìtem-${index}`}
                                to={`/services/${item.categorySlug}/`}
                                title={getIntlLabel(item, 'categoryName')}
                                description={getIntlLabel(item, 'categoryDescription')}
                            />
                        ))}
                </ContainerListStyled>
            </Container>
        </ContainerStyled>
    )
}

export default HomeServiceUnpopularSection
